import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => {
  const [openContact, SetOpenContact] = React.useState(false)

  return (
    <Layout formTrigger={openContact} cb={SetOpenContact}>
      <SEO title="Platform" />
      <section>
        <div className="container terms-content">
          <h1>CANCELLATION RETURN & REFUND POLICY</h1>

          <p>
            Return & Refund: <br />
            All cancellations must be in writing and sent to our office or emailed to kavitam@kcapital-us.com. Cancellations made more than two weeks in advance of the programme will be charged at 50% of the invoice total. Cancellations made after this date will be charged at the full invoice total.
            However change of nomination of the delegate is possible. No shows of the registered delegates will be billed as per the prevailing tariff.
            Once return is approved, refund will be processed after the program (after 18 Dec 2020) within 15 working days.
          </p>

          {/* <p>
            {" "}
            All cancellations must be in writing and sent to our office or
            emailed to{" "}
            <a href="mailto:kavitam@kcapital-us.com">kavitam@kcapital-us.com</a>
            . Cancellation of nominations by 28th February 2020 in advance
            attract 10% cancellation fee for Indian delegates and 20% of the
            registration fee in case of International delegates. The balance
            amount will be refunded after the program by Cheque or Fund Transfer
            within 15 working days.
          </p>

          <p>
            {" "}
            Cancellation of nominations by 16th March 2020 in advance attracts
            50% fee for Indian delegates and 70% fee in case of international
            delegates. Cancellation of nominations on or after 31st March 2020
            is not accepted and no refund will be made. However change of
            nomination of the delegate is possible. No shows of the registered
            delegates will be billed as per the prevailing tariff.
          </p> */}

          <p>
            {" "}
            <strong>Note:</strong>: 100% Advance payment. Places are not held until fees have been received.
          </p>
        </div>
      </section>
    </Layout>
  )
}
